import React, { createContext, useState, ReactNode } from 'react';
import { User } from './UserContext';
import type { Document } from '../types/types';

export interface DocumentVersion {
  id: string;
  versionUUID: string;
  createdAt: string;
  fileMetaData: { path: string };
  documentId: string;
  updatedBy: string;
  owner: User;
}

// Define context type
interface DocumentContextType {
  document: Document | null;
  documents: Document[];
  setDocument: (_document: Document | null) => void;
  setDocuments: (_documents: Document[]) => void;
  isLoading: boolean;
  otherDetails: any;
  setOtherDetails: (payload: any) => void;
  setSaveDocTrigger: (action: boolean) => void;
  saveTriggered: boolean;
  documentVersion?: DocumentVersion[];
  setDocumentVersion: (_docVesions: DocumentVersion[]) => void;
}

const DocumentContext = createContext<DocumentContextType>({
  document: null,
  documents: [],
  setDocument: (_document: Document | null) => {},
  setDocuments: (_documents: Document[]) => {},
  isLoading: false,
  otherDetails: null,
  setOtherDetails: (_payload: any) => {},
  setSaveDocTrigger: (action: boolean) => {},
  saveTriggered: false,
  documentVersion: [],
  setDocumentVersion: (_docVesions: DocumentVersion[]) => {},
});

// User provider component
const DocumentProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [document, setDocument] = useState<Document | null>(null);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [documentVersion, setDocumentVersion] = useState<DocumentVersion[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [saveTriggered, setSaveDocTrigger] = useState<boolean>(false);

  const [otherDetails, setOtherDetails] = useState<any>(null);

  // CRUD Operations

  // Value object to pass into context provider
  const value: DocumentContextType = {
    document,
    documents,
    setDocument,
    setDocuments,
    isLoading,
    setOtherDetails,
    otherDetails,
    setSaveDocTrigger,
    saveTriggered,
    documentVersion,
    setDocumentVersion,
  };

  return (
    <DocumentContext.Provider value={value}>
      {children}
    </DocumentContext.Provider>
  );
};

export { DocumentProvider, DocumentContext, DocumentContextType, Document };
export default DocumentProvider;
