/* eslint-disable @typescript-eslint/no-unused-vars */
import APIs from '../../config/apis';
import { useState } from 'react';
import apiClient, { BASE_URL } from '../../config/axiosConfig';
import { Document, DocumentVersion } from '../../contexts/DocumentContext';
import { useDocumentContext } from '../context/useDocumentContext';
import { useGroupContext } from '../context/useGroupContext';
import { useAuthContext } from '../context/useAuthContext';
import { getDocumentViewUrl, getUserFullName } from '../../utils/utils';
import { showToast } from 'design-web';
import { PermissionTypes } from '../../types/types';
import { User } from '../../contexts/UserContext';
import { useAppContext } from '../context/useAppContext';

export interface SharedUser extends User {
  permission?: PermissionTypes;
  userId: string;
}

const useDocumentService = () => {
  const [data, setData] = useState<Document[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { setOtherDetails, setDocument, setDocumentVersion } =
    useDocumentContext();
  const { updateGroupType, setGroupType } = useGroupContext();
  const { setActiveGroup } = useAppContext();
  const { userInfo } = useAuthContext();
  const [downloadStatuses, setDownloadStatuses] = useState<
    Record<string, { status: string; zipFileName?: string }>
  >({});

  // Function to check file names
  const checkFileNames = async (
    fileNames: string[],
    groupId: string,
    parentId?: string
  ): Promise<any> => {
    try {
      if (!fileNames || !groupId) {
        setError('File names and groupId are required');
        return null;
      }

      setLoading(true);

      // API call to check the validity of file names
      const response = await apiClient({
        ...APIs.DOCUMENT.CHECK_FILE_NAMES,
        data: { fileNames, groupId, parentId },
      });
      const validFileNames = response.data;

      if (response.status === 200) {
        return validFileNames;
      }

      if (response.status === 400) {
        throw new Error(response.data.message);
      }

      return null;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const createDocument = async (
    documentData: any
  ): Promise<Document | null> => {
    try {
      if (!documentData) {
        setError('No document data provided');
        return null;
      }
      setLoading(true);
      const response = await apiClient({
        ...APIs.DOCUMENT.CREATE,
        data: documentData,
      });
      const documentRes = response.data;
      if (response.status === 200) {
        setData([...data, documentRes]);
      }
      if (response.status === 400) {
        throw new Error(response.data.message);
      }

      return documentRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const updateDocument = async (
    documentId: string,
    documentData: any
  ): Promise<Document | null> => {
    try {
      setLoading(true);
      const response = await apiClient({
        ...APIs.DOCUMENT.PATCH(documentId),
        data: documentData,
      });

      const documentRes = response.data;
      if (response.status === 400) {
        throw new Error(response.data.message);
      }
      console.log(data, 'data');
      const result = [
        ...data.map(item =>
          item.id === documentRes.id ? { ...item, ...documentRes } : item
        ),
      ];
      console.log(result, 'result');
      setData(result);

      return documentRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const deleteDocument = async (id: string): Promise<Document | null> => {
    try {
      setLoading(true);
      const response = await apiClient({
        ...APIs.DOCUMENT.DELETE(id),
      });
      const documentRes = response.data;
      if (response.status === 200) {
        setData(data.filter(item => item.id !== id));
      }
      return documentRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const deleteDocumentVersion = async (
    id: string,
    vid: string
  ): Promise<Document | null> => {
    try {
      setLoading(true);
      const response = await apiClient({
        ...APIs.DOCUMENT.DELETE_VERSION(id, vid),
      });
      const documentRes = response.data;

      return documentRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const getDocumentList = async (
    groupId: string,
    parentId: string | null,
    queryPayload: any = { searchBy: '' },
    signal?: AbortSignal
  ): Promise<Document[] | null> => {
    try {
      setLoading(true);
      // check if it's group document or a folder documents
      let documentListEndPoint = APIs.GROUP.GET_ALL_DOCUMENTS(groupId);
      if (parentId) {
        documentListEndPoint = APIs.DOCUMENT.GET_ALL(parentId);
      }

      console.log('API Endpoint:', documentListEndPoint.url); // Debug: Verify endpoint URL
      console.log('Query Payload Sent:', queryPayload);
      const response = await apiClient({
        ...documentListEndPoint,
        params: queryPayload,
        signal,
      });

      // add DocumentShare data to childern if it's not present, from it's parent
      if (
        response.data?.data?.children &&
        response.data?.data?.DocumentShare?.length
      ) {
        response.data?.data?.children.map((doc: any) => {
          doc.DocumentShare = doc.DocumentShare?.length
            ? doc.DocumentShare
            : response.data?.data?.DocumentShare;
        });
      }
      const isLocalSearch = queryPayload?.isLocalSearch === true;
      const documentRes = isLocalSearch
        ? response.data?.data?.documents || response.data?.data?.searchedData
        : response.data?.data?.documents || response.data?.data?.children;
      const breadcrumbRes = response.data?.data?.breadcrumb || [];
      setGroupType('own');
      setOtherDetails({ group: response.data?.data?.group });
      setActiveGroup(response.data?.data?.group);
      if (response.data?.data?.documents) {
        const otherDetails = {
          ...(response.data?.data?.documents[0] || []),
          group: response.data?.data?.group,
          breadcrumb: breadcrumbRes,
        };
        setOtherDetails(otherDetails);
        updateGroupType(otherDetails);
      } else if (response.data?.data?.children) {
        setOtherDetails({ ...response.data?.data, children: null });
        updateGroupType(response.data?.data);
      }

      if (response.status === 200) {
        setData(documentRes);
        console.log(documentRes);
      }
      if (response.status === 400) {
        throw new Error(response.data.message);
      }
      return documentRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      showToast(
        'error',
        ex?.data?.message || ex.message || 'Something went wrong'
      );
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const getDocumentDetail = async (
    groupId: string,
    docId: string
  ): Promise<Document[] | null> => {
    try {
      setLoading(true);
      // check if it's group document or a folder documents
      const documentListEndPoint = APIs.DOCUMENT.DETAIL(docId);
      const response = await apiClient({
        ...documentListEndPoint,
      });

      const documentRes = response.data?.data;
      setGroupType('own');
      setOtherDetails({ group: response.data?.data?.group });
      if (response.data?.data) {
        const otherDetails = {
          ...response.data?.data,
        };
        setOtherDetails(otherDetails);
        updateGroupType(otherDetails);
        setDocument(otherDetails);
      }
      if (response.status === 400) {
        throw new Error(response.data.message);
      }
      return documentRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      showToast(
        'error',
        ex?.data?.message || ex.message || 'Something went wrong'
      );
      throw new Error(ex);
      return null;
      // throw ex;
    } finally {
      setLoading(false);
    }
  };

  const getDocumentVersions = async (
    docId: string
  ): Promise<DocumentVersion[] | null> => {
    try {
      setLoading(true);
      const documentVersionEndPoint = APIs.DOCUMENT.VERSION(docId);
      const response = await apiClient(documentVersionEndPoint);

      if (response.status === 400) {
        throw new Error(response.data.message);
      }
      const docVersionList = response.data?.data;
      console.log('docvserlist', docVersionList);

      setDocumentVersion(docVersionList);

      return docVersionList;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      showToast(
        'error',
        ex?.data?.message || ex.message || 'Something went wrong'
      );
      throw new Error(ex);
    } finally {
      setLoading(false);
    }
  };

  const multipleDocumentsDownload = async (
    documentIds: string[]
  ): Promise<void> => {
    try {
      setLoading(true); // Show loading indicator

      // Fetch the pre-signed URLs from the backend
      const response = await apiClient({
        ...APIs.DOCUMENT.DOCUMENT_DOWNLOAD(),
        data: { ids: documentIds },
      });

      if (response.status === 200 && response.data?.data) {
        const presignedUrls = response.data.data; // Extract the array of pre-signed URLs

        // Sequentially download each document
        for (const { id, preSignedUrl } of presignedUrls) {
          if (preSignedUrl) {
            console.log(`Downloading document ID: ${id}, URL: ${preSignedUrl}`);

            // Open in new tab (May be blocked for multiple links)
            window.open(preSignedUrl, '_blank');

            // Add a slight delay to avoid browser pop-up blocking
            await new Promise(resolve => setTimeout(resolve, 300)); // 300ms delay
          } else {
            console.error(`Missing preSignedUrl for document ID: ${id}`);
          }
        }
      } else {
        throw new Error('Failed to fetch pre-signed URLs for documents.');
      }
    } catch (error: any) {
      console.error('Error downloading documents:', error);
      setError(error.message); // Handle and display error
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };

  const multipleFoldersDownload = async (
    folderIds: string[],
    userId: string
  ): Promise<void> => {
    try {
      setLoading(true); // Show loading indicator

      // Fetch the pre-signed URLs for folders from the backend
      const response = await apiClient({
        ...APIs.DOCUMENT.MULTIPLE_FOLDERS_DOWNLOAD(),
        data: { userId: userId, s3Keys: folderIds },
      });

      if (response && response.data) {
        const folderData = response.data;

        // Create a new status object for each folder
        const statuses = folderData.reduce(
          (acc: Record<string, { status: string; id?: string }>, item: any) => {
            acc[item.id] = {
              status: item.result?.status || 'unknown', // Handle missing status gracefully
              id: item.id,
            };
            return acc;
          },
          {}
        );

        // Update state with new statuses and existing statuses
        setDownloadStatuses(prev => ({
          ...prev,
          ...statuses,
        }));

        return folderData;
      } else {
        throw new Error('Failed to fetch pre-signed URLs for folders');
      }
    } catch (error: any) {
      console.error('Error downloading folders:', error);
      setError(error.message); // Handle and display error
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };

  const fetchDownloadStatuses = async (
    userId: string,
    documentIds: string[]
  ) => {
    try {
      const response = await apiClient({
        ...APIs.DOCUMENT.MULTIPLE_FOLDERS_DOWNLOAD_STATUS(userId),
        data: { documentIds },
      });

      // Check if response.data is defined and is an array
      if (response && response.data && Array.isArray(response.data)) {
        const newStatuses = response.data.reduce(
          (
            acc: Record<string, { status: string; zipFileName?: string }>,
            item: any
          ) => {
            // Ensure `download` and `documentId` exist in the response
            if (item.download && item.download.documentId) {
              acc[item.download.documentId] = {
                status: item.download.status || 'unknown', // Default to 'unknown' if status is missing
                zipFileName: item.download.zipFileName || '', // Default to empty string if zipFileName is missing
              };
            } else {
              console.warn('Missing expected download data:', item);
            }
            return acc;
          },
          {}
        );

        // Update the statuses in state
        setDownloadStatuses(prev => ({ ...prev, ...newStatuses }));

        // Returning the response items if necessary
        return response.data.items || response.data;
      } else {
        throw new Error('Invalid response format from server');
      }
    } catch (error: any) {
      console.error('Error fetching download statuses:', error);
      setError(error.message); // Display the error message to the user
    }
  };

  const downloadDocumentVersion = async (versionId: string) => {
    try {
      // Fetch the presigned URL for the document version
      const response = await apiClient({
        ...APIs.DOCUMENT.DOCUMENT_VERSION_DOWNLOAD(versionId), // Make sure you have the right endpoint for getting the presigned URL
      });

      if (response.status === 400) {
        throw new Error(response.data.message);
      }
      const presignedUrl = response.data?.data;
      console.log('Presigned URL:', presignedUrl);
      // Open the document download link in a new tab
      window.open(presignedUrl, '_blank');
    } catch (ex: any) {
      console.error('Error downloading document version:', ex);
      setError(ex?.data);
      showToast('error', ex?.data?.message || 'Something went wrong');
      throw new Error(ex.data.message);
    }
  };

  const shareDocument = async (
    documentId: string,
    requestPayload: any,
    action: 'SHARE' | 'SHARE_PATCH'
  ): Promise<void> => {
    try {
      setLoading(true);
      const response = await apiClient({
        ...(action === 'SHARE'
          ? APIs.DOCUMENT.SHARE(documentId)
          : APIs.DOCUMENT.SHARE_UPDATE(documentId)),
        data: requestPayload,
      });

      const documentRes = response.data;
      if (response.status === 400) {
        throw new Error(response.data.message);
      }
      return documentRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex?.data);
      showToast('error', ex?.data?.message || 'Something went wrong');
      throw new Error(ex.data.message);
    } finally {
      setLoading(false);
    }
  };

  const getSharedDocumentUsers = async (
    documentId: string
  ): Promise<SharedUser[]> => {
    try {
      setLoading(true);
      const response = await apiClient({
        ...APIs.DOCUMENT.GET_SHARED_USERS(documentId),
      });

      const documentRes = response.data?.data?.map((sharedUser: any) => {
        return {
          ...sharedUser?.user,
          ...sharedUser,
          permission: sharedUser.action,
          name: getUserFullName(sharedUser.user),
        };
      });
      if (response.status === 400) {
        throw new Error(response.data.message);
      }
      return documentRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex?.data);
      showToast('error', ex?.data?.message || 'Something went wrong');
      throw new Error(ex.data.message);
    } finally {
      setLoading(false);
    }
  };

  const createDocumentVersion = async (
    documentId: string,
    requestPayload: any
  ): Promise<void> => {
    try {
      setLoading(true);
      const response = await apiClient({
        ...APIs.DOCUMENT.CREATE_VERSION(documentId),
        data: requestPayload,
      });
      const documentRes = response.data;
      if (response.status === 400) {
        throw new Error(response.data.message);
      }
      // showToast('success', `Document updated successfully`);
      return documentRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex?.data);
      showToast('error', ex?.data?.message || 'Something went wrong');
      throw new Error(ex.data.message);
    } finally {
      setLoading(false);
    }
  };

  const uploadDocumentToS3 = async (
    docArrayBuffer: File,
    signedUrl: string
  ): Promise<Response> => {
    try {
      setLoading(true);
      const response = await fetch(signedUrl, {
        method: 'PUT',
        body: docArrayBuffer,
        headers: {
          'Content-Type': 'application/octet-stream', // Ensure correct content type
          'x-amz-server-side-encryption': 'aws:kms',
        },
      });
      const documentRes = response;
      if (response.status === 400) {
        throw new Error('Error in uploading document');
      }
      // showToast('success', `Document version uploaded successfully`);
      return documentRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex?.data);
      showToast('error', ex?.data?.message || 'Something went wrong');
      throw new Error(ex.data.message);
    } finally {
      setLoading(false);
    }
  };

  const restoreMultipleUsers = async (
    ids: string[]
  ): Promise<User[] | null> => {
    try {
      setLoading(true);
      const response = await apiClient({
        ...APIs.DOCUMENT.RESTORE_MULTIPLE(), // Adjust the API endpoint for restoring multiple users
        data: { ids }, // Pass the array of user IDs
      });
      console.log('response restore multiple users', response);
      const usersRes = response.data?.data;

      if (response.status === 200) {
        // Update the user state or data if necessary
        const updatedData = data.map(item =>
          ids.includes(item.id) ? { ...item, isDeleted: false } : item
        );
        setData(updatedData);
      }
      return usersRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const deleteMultipleDocuments = async (ids: string[]): Promise<void> => {
    try {
      setLoading(true);
      const response = await apiClient({
        ...APIs.DOCUMENT.DELETE_PERMANENT(),
        data: { ids }, // Send an array of document IDs
      });

      console.log('Response delete multiple documents', response);

      if (response.status === 200) {
        // Remove the deleted documents from the local state
        const updatedData = data.filter(item => !ids.includes(item.id));
        setData(updatedData);
      }
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const getDeletedDocuments = async (
    groupId: string,
    queryPayload: any = { searchBy: '' },
    signal?: AbortSignal
  ): Promise<Document[] | null> => {
    try {
      setLoading(true);

      // API endpoint for fetching deleted documents
      const deletedDocumentsEndpoint =
        APIs.GROUP.GET_DELETED_DOCUMENTS(groupId);

      const response = await apiClient({
        ...deletedDocumentsEndpoint,
        params: queryPayload,
        signal,
      });

      const documentRes = response.data?.data || [];

      if (response.status === 200) {
        setData(documentRes);
        console.log(documentRes);
      }
      if (response.status === 400) {
        throw new Error(response.data.message);
      }
      return documentRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      showToast(
        'error',
        ex?.data?.message || ex.message || 'Something went wrong'
      );
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const moveDocument = async (
    documentId: string,
    parentId: string | null
  ): Promise<void> => {
    try {
      setLoading(true);
      const response = await apiClient({
        ...APIs.DOCUMENT.MOVE_DOCUMENT(documentId),
        data: { parentId },
      });
      const documentRes = response.data;
      if (response.status === 400) {
        throw new Error(response.data.message);
      }

      // Handle successful document move
      showToast('success', 'Document moved successfully');
      return documentRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex?.data);
      showToast('error', ex?.data?.message || 'Something went wrong');
      throw new Error(ex.data.message);
    } finally {
      setLoading(false);
    }
  };

  const getFoldersList = async (
    groupId: string,
    queryPayload: any = { searchBy: '' }
  ): Promise<Document[] | null> => {
    try {
      setLoading(true);
      const response = await apiClient({
        ...APIs.DOCUMENT.GET_GROUP_FOLDERS(groupId),
        data: { isGroup: true },
        params: queryPayload,
      });

      if (response.status === 200) {
        const documentRes = response.data?.data || [];
        setData(documentRes);
        return documentRes;
      } else {
        throw new Error(
          response.data?.message || 'Failed to fetch group folders'
        );
      }
    } catch (error: any) {
      console.error('Error fetching group folders:', error);
      return Promise.reject(error);
    }
  };

  const getFolderChildrenList = async (
    parentId: string,
    queryPayload: any = { searchBy: '' }
  ): Promise<Document[] | null> => {
    try {
      setLoading(true);
      const response = await apiClient({
        ...APIs.DOCUMENT.GET_CHILD_FOLDERS(parentId),
        data: { isGroup: false },
        params: queryPayload,
      });

      console.log('Child Folders Response:', response.data);
      if (response.status === 200) {
        const documentRes = response.data?.data || [];
        setData(documentRes);
        return documentRes;
      } else {
        throw new Error(
          response.data?.message || 'Failed to fetch child folders'
        );
      }
    } catch (error: any) {
      console.error('Error fetching child folders:', error);
      return Promise.reject(error);
    }
  };

  return {
    loading,
    data,
    error,
    createDocument,
    checkFileNames,
    updateDocument,
    deleteDocument,
    getDocumentList,
    multipleDocumentsDownload,
    multipleFoldersDownload,
    downloadDocumentVersion,
    getDocumentDetail,
    getDocumentVersions,
    shareDocument,
    getSharedDocumentUsers,
    createDocumentVersion,
    uploadDocumentToS3,
    deleteDocumentVersion,
    downloadStatuses,
    fetchDownloadStatuses,
    restoreMultipleUsers,
    deleteMultipleDocuments,
    getDeletedDocuments,
    moveDocument,
    getFoldersList,
    getFolderChildrenList,
  };
};

export default useDocumentService;
