import React from 'react';
import { NestedMenu } from './NestedMenu';
import { Document } from 'shared/contexts/DocumentContext';

interface AccordianProps {
  data: Document[];
  onFolderMove: (folderData: Document, newParentId: string | null) => void;
}

export const Accordian: React.FC<AccordianProps> = ({ data, onFolderMove }) => {
  return (
    <div>
      {data?.map((ele, index) => (
        <div key={index}>
          <NestedMenu data={ele} onFolderMove={onFolderMove} />
        </div>
      ))}
    </div>
  );
};
