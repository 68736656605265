import React, { createContext, useState, ReactNode } from 'react';
import type { Document } from '../types/types';

// Define context type
interface AppContextType {
  activeGroup: any;
  setActiveGroup: (payload: any) => void;
  movedFolder: Document | null;
  setMovedFolder: (_document: Document | null) => void;
  refreshFolderByParentId: string | null;
  setRefreshFolderByParentId: (_parentId: string) => void;
  refreshRootFolders: boolean;
  setRefreshRootFolders: (_refresh: boolean) => void;
  childrenData: Document[];
  setChildrenData: (_documents: Document[]) => void;
}

// Create Context
const AppContext = createContext<AppContextType>({
  activeGroup: null,
  setActiveGroup: (_payload: any) => {},
  movedFolder: null,
  setMovedFolder: (_document: Document | null) => {},
  refreshFolderByParentId: null,
  setRefreshFolderByParentId: (_parentId: string) => {},
  refreshRootFolders: false,
  setRefreshRootFolders: () => {},
  childrenData: [],
  setChildrenData: (_documents: Document[]) => {},
});

// Context Provider Component
const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [activeGroup, setActiveGroup] = useState<any>(null);
  const [movedFolder, setMovedFolder] = useState<Document | null>(null);
  const [refreshFolderByParentId, setRefreshFolderByParentId] =
    useState<string>('');
  const [refreshRootFolders, setRefreshRootFolders] = useState<boolean>(false);
  const [childrenData, setChildrenData] = useState<Document[]>([]);

  const value: AppContextType = {
    activeGroup,
    setActiveGroup,
    movedFolder,
    setMovedFolder,
    refreshFolderByParentId,
    setRefreshFolderByParentId,
    refreshRootFolders,
    setRefreshRootFolders,
    childrenData,
    setChildrenData,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export { AppProvider, AppContext, AppContextType, Document };
export default AppProvider;
