import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import { useEffect, useRef, useState } from 'react';
// import sortBy from 'lodash/sortBy';
import Input from '../Input/Input';
import Icon from '../Icon/Icon';
import Loader from '../Loader/Loader';
import React from 'react';

export type ColomnProps = {
  key: string;
  title: string;
  textAlign?: 'left' | 'center' | 'right';
  sortable?: boolean;
  width?: string;
};

// Table component

export type TableProps = {
  data: any[];
  coloums: ColomnProps[];
  exportAndSearchBar?: boolean;
  infiniteScrolling?: boolean;
  loadMoreRecords?: () => void;
  onSort?: (data: DataTableSortStatus) => void;
  loadingData?: boolean;
  initSortStatus?: DataTableSortStatus;
  enableSelection?: boolean;
  selectedItems?: any[];
  setSelectedItems?: (items: any[]) => void;
  selectionCheckboxProps?: React.ComponentProps<
    typeof DataTable
  >['selectionCheckboxProps']; // Add this line
};

export const Table: React.FC<TableProps> = ({
  data,
  coloums,
  exportAndSearchBar = false,
  infiniteScrolling = false,
  loadMoreRecords,
  onSort,
  loadingData = false,
  initSortStatus = {
    columnAccessor: 'id',
    direction: 'asc',
  },
  enableSelection = false,
  selectedItems = [],
  setSelectedItems = () => {},
  selectionCheckboxProps = {},
}) => {
  const dataColoumn = coloums.map(coloum => {
    return {
      accessor: coloum.key,
      title: coloum.title,
      textAlign: coloum.textAlign || 'left',
      sortable: coloum.sortable || false,
      width: coloum.width,
    };
  });

  // console.log(sortBy(rowData, 'id'), 'row Data');
  const [page, setPage] = useState(1);
  const PAGE_SIZES = [10, 20, 30, 50, 100];
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const [initialRecords, setInitialRecords] = useState<any[]>([]);
  const [recordsData, setRecordsData] = useState(initialRecords);

  const [selectedRecords, setSelectedRecords] = useState<any>([]);

  const [search, setSearch] = useState('');
  const [sortStatus, setSortStatus] =
    useState<DataTableSortStatus>(initSortStatus);
  const [loading, setLoading] = useState(loadingData);
  const scrollViewportRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  useEffect(() => {
    const from = (page - 1) * pageSize;
    const to = from + pageSize;

    setRecordsData([...initialRecords]);
  }, [page, pageSize, initialRecords]);

  // useEffect(() => {
  //   setInitialRecords(() => {
  //     return initialRecords.filter(item => {
  //       return (
  //         (search &&
  //           (item.id.toString().includes(search.toLowerCase()) ||
  //             item.avatar.toLowerCase().includes(search.toLowerCase()))) ||
  //         true
  //       );
  //     });
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [search]);

  useEffect(() => {
    const rowData: any[] = data.map((record, index) => {
      return {
        id: index,
        ...record,
      };
    });
    setInitialRecords(rowData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // useEffect(() => {
  //   const data = sortBy(initialRecords, sortStatus.columnAccessor);
  //   setInitialRecords(sortStatus.direction === 'desc' ? data.reverse() : data);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sortStatus]);

  useEffect(() => {
    // setLoading(false);
  }, [data]);

  const handleSorting = (data: DataTableSortStatus) => {
    setSortStatus(data);
    if (onSort) onSort(data);
    setLoading(true);
  };
  return (
    <div
      className="overflow-y-auto mx-5 mt-5 py-3 "
      style={{
        maxHeight: 'calc(100vh - 15rem)',
        padding: '0',
        borderRadius: '0.5rem',
      }}
    >
      {exportAndSearchBar ?? (
        <div className="flex items-center mb-5 justify-end">
          {' '}
          {/* Add justify-end to align items to the right */}
          {/* <button className="btn !mt-5 border-0 bg-primary capitalize text-white py-2.5">
            Export Selected as VCF
          </button> */}
          <div className="relative ml-4 mt-5 ">
            {' '}
            {/* Add ml-4 margin-left for spacing */}
            <Input
              name="search"
              type="text"
              className="p-2 pl-10 border border-gray-300 rounded-md w-auto"
              placeholder="Search..."
              value={search}
              onChange={e => setSearch(e)}
            />
            <Icon
              name="Search"
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
            />
            {/* Search icon */}
          </div>
        </div>
      )}
      <div className="datatables">
        {infiniteScrolling ? (
          <DataTable
            className="table-hover whitespace-nowrap"
            height="calc(100vh - 15rem)"
            records={recordsData}
            columns={dataColoumn}
            fetching={loadingData}
            onScrollToBottom={() => {
              //setLoading(true);
              loadMoreRecords && loadMoreRecords();
            }}
            scrollViewportRef={scrollViewportRef}
            customLoader={<Loader />}
            scrollAreaProps={{ type: 'never' }}
            sortStatus={sortStatus}
            onSortStatusChange={handleSorting}
            {...(enableSelection && {
              selectedRecords: selectedItems,
              onSelectedRecordsChange: setSelectedItems,
              highlightOnHover: true,
            })}
            selectionCheckboxProps={selectionCheckboxProps}
          />
        ) : (
          <DataTable
            className="whitespace-nowrap table-hover"
            highlightOnHover={true}
            withColumnBorders={true}
            withTableBorder={true}
            totalRecords={initialRecords.length}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={p => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            sortStatus={sortStatus}
            onSortStatusChange={setSortStatus}
            minHeight={200}
            paginationText={({ from, to, totalRecords }) =>
              `Showing  ${from} to ${to} of ${totalRecords} entries`
            }
            columns={dataColoumn}
            records={recordsData}
            scrollAreaProps={{ type: 'never' }}
            fetching={loadingData}
            {...(enableSelection && {
              selectedRecords: selectedItems,
              onSelectedRecordsChange: setSelectedItems,
              highlightOnHover: true,
            })}
            selectionCheckboxProps={selectionCheckboxProps}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(Table);
