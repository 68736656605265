import React, { useState, useEffect } from 'react';
import { Button, Dropdown, showToast } from 'design-web';
import {
  Formik,
  Field,
  Form,
  FieldArray,
  FormikHelpers,
  ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import useDocumentService from 'shared/hooks/service/useDocumentService';
import Modal from 'packages/web/src/components/Modal';
import { useTheme } from 'shared/hooks/useTheme';
import { Document } from 'shared/contexts/DocumentContext';
import { useDocumentContext } from 'shared/hooks/context/useDocumentContext';
import { useParams } from 'react-router-dom';
import DataSaveLoader from 'packages/web/src/components/DataSaveLoader';
import { useAuthContext } from 'shared/hooks/context/useAuthContext';
import { useAppContext } from 'shared/hooks/context/useAppContext';

interface DocumentFormProps {
  editDocument?: Document | null;
  type: 'doc' | 'folder';
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setEditDocument: React.Dispatch<React.SetStateAction<Document | null>>;
  parentId?: string | null;
}

export const DocumentForm: React.FC<DocumentFormProps> = ({
  editDocument = null,
  type = 'folder',
  setIsModalVisible,
  setEditDocument, // <-- New prop
  parentId = null,
}) => {
  const { documents: data, setDocuments } = useDocumentContext();
  const { setRefreshFolderByParentId, setRefreshRootFolders } = useAppContext();
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);
  const { isMobile } = useTheme();
  const { getDocumentList, createDocument, updateDocument, loading } =
    useDocumentService();
  const { userInfo } = useAuthContext();
  const groupId = useParams().groupId;
  const folderId = useParams().folderId;
  const isEditMode = !!editDocument;
  // Extract base name and extension from the document name
  const getExtension = (fileName: string) => fileName.split('.').pop();
  const getBaseName = (fileName: string) =>
    fileName.split('.').slice(0, -1).join('.');

  // Get initial base name and extension for editing mode
  const initialBaseName = isEditMode ? getBaseName(editDocument.name) : '';
  const extension = isEditMode ? getExtension(editDocument.name) : '';

  const initialValues = isEditMode
    ? {
        name: type === 'doc' ? initialBaseName : editDocument.name,
      }
    : { name: '' };

  const DocLabel = type === 'folder' ? 'Folder' : 'Document';

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(`${DocLabel} name is required`),
    // .matches(passwordValidationRegex, passwordValidationMessage),
  });

  const handleSubmit = async (
    values: any,
    actions: FormikHelpers<any>
  ): Promise<void> => {
    setIsSubmitInProgress(true);
    try {
      const fullName = `${values.name.trim()}.${extension}`;
      const newName = type === 'folder' ? values.name.trim() : fullName;
      // Create a new values object without modifying the formik values directly.
      const payload = {
        name: newName,
        groupId,
        isFolder: type === 'folder' ? true : false,
        parentId: folderId,
      };

      if (isEditMode) {
        const result = await updateDocument(editDocument.id, payload); // Assume updateDocument is the API call for updating the user
        if (result) {
          setDocuments([
            ...data.map(item =>
              item.id === result.id ? { ...item, ...result } : item
            ),
          ]);
          if (result) setRefreshFolderByParentId(result.parentId || '');
          setRefreshRootFolders(true);
          showToast('success', `${DocLabel} Name Updated Successfully.`);
        }
      } else {
        const result = await createDocument(payload);
        if (result) {
          result.user = result.user || userInfo;
          setDocuments([result, ...data]); // Assuming data is the current list of roles
          if (result) setRefreshFolderByParentId(result.parentId || '');
          setRefreshRootFolders(true);
          showToast('success', `${DocLabel} Created Successfully.`);
        }
      }
      setIsSubmitInProgress(false);
      console.log(values);
      actions.setSubmitting(false);
      setIsModalVisible(false);
      setEditDocument(null);
    } catch (err: any) {
      setIsSubmitInProgress(false);
      console.log('err', err);
      // Handle "Folder already exists" error from the backend
      if (err && err.data.message === 'Folder already exists.') {
        // Use setFieldError to show the validation error on the name field
        actions.setFieldError('name', 'Folder with this name already exists');
      } else if (err && err.data.message === 'File already exists.') {
        actions.setFieldError('name', 'Document with this name already exists');
      } else {
        showToast('error', err?.data?.message || 'An error occurred');
      }
    }
  };

  const CustomButton: React.FC<{
    type: 'submit' | 'button';
    className: string;
    onClick?: () => void;
    label: string;
    disabled?: boolean;
  }> = ({ type, className, onClick, label, disabled = false }) => (
    <Button
      type={type}
      className={className}
      disabled={disabled}
      onClick={onClick}
    >
      {label}
    </Button>
  );

  return (
    <Modal
      title={isEditMode ? `Edit ${DocLabel}` : `Add New ${DocLabel}`}
      loading={false}
      onClose={() => {
        setIsModalVisible(false);
        setEditDocument(null); // Reset editDocument when closing the modal
      }}
      isOpen={true}
      className={`max-w-xl px-10`}
    >
      {' '}
      {<DataSaveLoader visible={isSubmitInProgress} />}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, setFieldValue, dirty }) => (
          <Form>
            <>
              <div className="flex flex-col items-center w-full mt-4 gap-4 p-2 sm:p-1">
                <div className="w-full">
                  <label
                    htmlFor={`name`}
                    className="block text-semiBase mb-2 sm:text-base font-semibold text-gray-700 font-montserrat leading-[19.5px] text-left"
                  >
                    {DocLabel} Name
                  </label>
                  <Field
                    type="text"
                    id={`name`}
                    name={`name`}
                    placeholder={`Enter ${DocLabel} Name`}
                    className={`mt-1 p-2 py-3 border ${
                      errors.name && touched.name
                        ? 'border-red-500'
                        : 'border-gray-300'
                    } rounded-xl w-full block mb-2 text-semiBase sm:text-base font-bold text-gray-500 font-inter text-left`}
                  />
                  <ErrorMessage
                    name={`name`}
                    component="div"
                    className="text-brandRed font-montserrat text-base font-medium leading-[19.5px] text-left mt-1"
                  />
                </div>
              </div>
            </>

            {isMobile && (
              <div className="mt-2 mb-14 mr-2 flex flex-col pt-12 gap-4">
                <CustomButton
                  type="submit"
                  className={`w-full py-2 whitespace-nowrap rounded-xl text-lg font-normal leading-[30px] text-center focus:outline-none focus:ring-2
                    ${
                      isEditMode && !dirty
                        ? 'bg-gray-400 text-gray-200 cursor-not-allowed'
                        : 'bg-primary-lightBlue text-white hover:bg-blue-500 focus:ring-blue-600'
                    }`}
                  label={isEditMode ? 'Save Changes' : `Add ${DocLabel}`}
                  disabled={isEditMode && !dirty}
                />
                <CustomButton
                  type="button"
                  className="w-full border border-primary rounded-xl py-2 bg-white text-primary hover:bg-primary-light focus:outline-none focus:ring-2 focus:ring-gray-400 text-lg font-normal leading-[30px] text-center"
                  onClick={() => setIsModalVisible(false)}
                  label=" Cancel"
                />
              </div>
            )}
            {!isMobile && (
              <div className="w-full mt-2 mb-10 pt-10 pl-2 pr-1 mr-8 flex justify-end gap-4">
                <CustomButton
                  type="button"
                  className="border w-1/2 border-primary whitespace-nowrap rounded-xl py-2 bg-white text-primary hover:bg-primary-light focus:outline-none focus:ring-2 focus:ring-gray-400 text-xl font-normal leading-[30px] text-center"
                  onClick={() => setIsModalVisible(false)}
                  label=" Cancel"
                />
                <CustomButton
                  type="submit"
                  className={`py-2 w-1/2 whitespace-nowrap rounded-xl text-xl font-normal leading-[30px] text-center focus:outline-none focus:ring-2
                    ${
                      isEditMode && !dirty
                        ? 'bg-gray-400 text-gray-200 cursor-not-allowed'
                        : 'bg-primary-lightBlue text-white hover:bg-blue-500 focus:ring-blue-600'
                    }`}
                  label={isEditMode ? 'Save Changes' : `Add ${DocLabel}`}
                  disabled={isEditMode && !dirty}
                />
              </div>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
