import React, { Suspense, useEffect, useState } from 'react';
import BaseLayout from './BaseLayout';
import { useTheme } from 'shared/hooks/useTheme';
import Header from './Header';
import { useAuthContext } from 'shared/hooks/context/useAuthContext';
import Sidebar from './Sidebar';

import { Button, Icon, PageLoader } from 'design-web';
import MobileNav from './MobileNav';
import GroupProvider from 'shared/contexts/GroupContext';
import AppProvider from 'shared/contexts/AppContext';

const DefaultLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { theme, sidebar, toggleSidebar, sidebarWidth } = useTheme();
  const { isLoading } = useAuthContext();
  const [showTopButton, setShowTopButton] = useState(false);

  const onScrollHandler = () => {
    // if (
    //   document.body.scrollTop > 50 ||
    //   document.documentElement.scrollTop > 50
    // ) {
    //   setShowTopButton(true);
    // } else {
    //   setShowTopButton(false);
    // }
  };

  const goToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    window.addEventListener('scroll', onScrollHandler);

    return () => {
      window.removeEventListener('onscroll', onScrollHandler);
    };
  });

  return (
    <GroupProvider>
      <AppProvider>
        <BaseLayout>
          {/* BEGIN MAIN CONTAINER */}
          <div className="relative">
            {/* sidebar menu overlay */}
            <div
              className={`${
                (!sidebar && 'hidden') || ''
              } fixed inset-0 bg-[black]/60 z-50 lg:hidden`}
              onClick={() => toggleSidebar()}
            ></div>
            {/* screen loader */}
            {isLoading && (
              <div className="screen_loader fixed inset-0 bg-[#fafafa] dark:bg-[#060818] z-[60] grid place-content-center animate__animated">
                <PageLoader />
              </div>
            )}
            {/* <div className="fixed bottom-6 ltr:right-6 rtl:left-6 right-0 z-50">
          {showTopButton && (
            <Button
              type="button"
              className="btn btn-outline-primary rounded-full p-2 animate-pulse bg-[#fafafa] dark:bg-[#060818] dark:hover:bg-primary"
              onClick={goToTop}
            >
              <Icon name="ArrowUp" height={24} width={24} className="h-4 w-4" />
            </Button>
          )}
        </div> */}

            {/* BEGIN APP SETTING LAUNCHER */}
            {/* <Setting /> */}
            {/* END APP SETTING LAUNCHER */}

            <div
              className={`${theme.navbar} flex justify-between main-container text-black dark:text-white-dark min-h-screen`}
            >
              {/* BEGIN SIDEBAR */}
              <Sidebar />
              {/* END SIDEBAR */}

              <div
                className={`${
                  !sidebar ? 'main-content-sidebar' : 'w-screen'
                } main-content flex flex-col h-screen`}
                style={
                  !sidebar && window.innerWidth > 1024
                    ? {
                        width: `calc(100% - ${sidebarWidth}px)`,
                        transition: 'width 0.3s ease-in-out',
                      }
                    : {}
                }
              >
                {/* BEGIN TOP NAVBAR */}
                <Header />
                {/* END TOP NAVBAR */}
                {/* <div className="block lg:hidden">
              <MobileNav />
            </div> */}
                {/* BEGIN CONTENT AREA */}
                <Suspense>
                  <div className={`${theme.animation} p-1 animate__animated`}>
                    {children}
                  </div>
                </Suspense>
                {/* END CONTENT AREA */}
              </div>
            </div>
            {/* BEGIN FOOTER */}
            {/* <Footer /> */}
            {/* END FOOTER */}
          </div>
        </BaseLayout>
      </AppProvider>
    </GroupProvider>
  );
};

export default DefaultLayout;
